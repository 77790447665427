import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logos/logo.svg'
import _imports_1 from '@/assets/images/icons/icon-share.svg'
import _imports_2 from '@/assets/images/icons/icon-twitter.svg'
import _imports_3 from '@/assets/images/icons/icon-facebook.svg'
import _imports_4 from '@/assets/images/icons/icon-whatsapp.svg'
import _imports_5 from '@/assets/images/icons/icon-download.svg'


const _hoisted_1 = { class: "page home" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "video full-width" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = {
  controls: "",
  preload: "metadata",
  playsinline: "",
  poster: "https://affectivity-storage.lwmicro.com/output/yer_pv/5bb8ff28b36dea273728538040374d3423d710fc.jpg"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "share" }
const _hoisted_8 = { class: "desktop-share" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "bg-line" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[12] || (_cache[12] = _createElementVNode("section", { class: "topbar" }, [
        _createElementVNode("a", {
          href: "https://www.yer.nl",
          target: "_blank",
          rel: "noopener noreferrer"
        }, [
          _createElementVNode("img", {
            src: _imports_0,
            class: "logo"
          })
        ])
      ], -1)),
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("h2", null, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.name), 1),
          _cache[5] || (_cache[5] = _createTextVNode(", ben jij er klaar voor om je ambities in beweging te brengen?"))
        ]),
        _cache[11] || (_cache[11] = _createElementVNode("p", null, " Bekijk jouw persoonlijke video hier! ", -1)),
        _createElementVNode("video", _hoisted_5, [
          _createElementVNode("source", { src: _ctx.videoSrc }, null, 8, _hoisted_6)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", {
            class: "button general",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleShare('general')))
          }, _cache[6] || (_cache[6] = [
            _createElementVNode("img", {
              src: _imports_1,
              class: "icon"
            }, null, -1)
          ])),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", {
              class: "button twitter",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleShare('twitter')))
            }, _cache[7] || (_cache[7] = [
              _createElementVNode("img", {
                src: _imports_2,
                class: "icon"
              }, null, -1)
            ])),
            _createElementVNode("div", {
              class: "button facebook",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleShare('facebook')))
            }, _cache[8] || (_cache[8] = [
              _createElementVNode("img", {
                src: _imports_3,
                class: "icon"
              }, null, -1)
            ])),
            _createElementVNode("div", {
              class: "button whatsapp",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleShare('whatsapp')))
            }, _cache[9] || (_cache[9] = [
              _createElementVNode("img", {
                src: _imports_4,
                class: "icon"
              }, null, -1)
            ])),
            _createElementVNode("div", {
              class: "button download",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleShare('download')))
            }, _cache[10] || (_cache[10] = [
              _createElementVNode("img", {
                src: _imports_5,
                class: "icon"
              }, null, -1)
            ]))
          ])
        ])
      ])
    ])
  ]))
}